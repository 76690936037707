import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "company": "Axonify",
  "title": "Software Developer Intern",
  "time": "Summer 2019",
  "index": 0,
  "type": "position",
  "hidden": false,
  "splash": "Designing & implementing new features for employee onboarding in the employee training system of tomorrow"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://axonify.com/"
      }}>{`Axonify`}</a></h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` May - Aug 2019`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` `}<inlineCode parentName="p">{`JavaScript`}</inlineCode>{` `}<inlineCode parentName="p">{`Java`}</inlineCode>{` `}<inlineCode parentName="p">{`BackboneJS`}</inlineCode>{` `}<inlineCode parentName="p">{`Hibernate`}</inlineCode>{` `}<inlineCode parentName="p">{`Bash`}</inlineCode></p>
    <p><strong parentName="p">{`Location:`}</strong>{` Ontario, Canada`}</p>
    <p><strong parentName="p">{`Team:`}</strong>{` Guided Learning`}</p>
    <hr></hr>
    <p>{`Axonify's product is an employee training service targeting frontline employees for organizations. Essentially the app provides an initial onboarding experience for new employees to get them up to speed called `}<em parentName="p">{`Guided Learning`}</em>{`, as well as an ongoing ML based training regimen for employees to keep fresh on their training knowledge. In addition to the benefit to frontline employees, the service also provides a variety of metrics on training completion to upper management.`}</p>
    <p>{`At Axonify I worked on the Guided Learning team developing a new feature for our app's onboarding flow. I worked on developing `}<em parentName="p">{`Milestones`}</em>{` - a feature intended to act like checkpoints giving trainees something to strive for while progressing through their onboarding tasks.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      